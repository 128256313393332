$(document).ready(function() {

    /*******************************************/
    //Form Validation
    $('.validate-form').each(function() {
        $(this).validate({
            submitHandler: function(form) {
                form.submit();
            },
            rules: {
                required: true
            },
            errorPlacement: function(error, element) {
                error.appendTo(element.parents('.form-field'));
            },
            highlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').addClass(errorClass).removeClass(validClass);
                } else {
                    $(element).parents('.form-field').addClass(errorClass).removeClass(validClass);
                }
                $(element).closest('.validate-form').addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').removeClass(errorClass).addClass(validClass);
                } else {
                    $(element).parents('.form-field').removeClass(errorClass).addClass(validClass);
                }
                $(element).closest('.validate-form').removeClass(errorClass).addClass(validClass);
            }
        });
    });


    /*******************************************/
    //Custom Select Dropdown
    $(function() {
        $('.custom-select-dropdown').selectric();
    });

    /*******************************************/
    //Lightbox Initiations
    $('.open-popup-link').magnificPopup({
        type:'inline',
        showCloseBtn: true,
        closeOnBgClick: true,
        removalDelay: 300,
        mainClass: 'mfp-zoom-in',
        midClick: true
    });

    $('.lightbox-manual-close-trigger').click(function(event) {
        e.preventDefault();
        $.magnificPopup.close();
    });

    /*******************************************/
    // Tabs
    $('.js-tab-triggers a').on('click', function(e) {
        e.preventDefault();
        $('.js-tab-triggers a').not(this).removeClass('active');
        $(this).addClass('active');
        var getTarget = $(this).attr('href');
        $('.tab-content').not(getTarget).hide();
        $(getTarget).show();
    });

    /*******************************************/

});


/*******************************************/
$.fn.toggleHTML = function(t1, t2) {
    if (this.html() == t1) this.html(t2);
    else this.html(t1);
    return this;
};


/*******************************************/
//Main Menu
function slideMenu(action){ 
    var $menu = $('#mobile-menu') 
    if (!action) { toggle(); return }
    if (action == "open") { open(); return }
    if (action == "close") { close(); return }

    function open(){
        $("body").addClass('open-menu');
        $menu.attr('status', 'open');
    }
    function close(){
        $("body").removeClass('open-menu');
        $menu.attr('status', 'closed');
    }
    function toggle(){
        var status =  $menu.attr('status');
        if (status == "open"){ close(); return }
        if (status == "closed"){ open(); return }
    }
}

$('#mobile-menu li').each(function(){
        $(this).has("ul").addClass('mobile-menu-parent');
});

$( ".mobile-menu-parent .icon" ).on("click", function(){
    $(this).siblings('ul').slideToggle(150);
});

//prevent slide up
$(".mobile-menu-parent").on("click", "ul", function(event){
    event.stopPropagation()
})

/*******************************************/
// Header Highlighter
$(document).ready(function() {
    var headerHref = "#site-header";
    var mobileHref = "#mobile-menu";
    var myURL = "/" + location.pathname.split('/')[1];

    $(headerHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).find('a').addClass("selected")
            return
        }
    })

    $(mobileHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).find('a').addClass("selected")
            return
        }
    })
});

/*******************************************/
// Search field hide/show
$(document).ready(function() {
    var searchField = $('#site-header').find('.js-search-field');

    $('.js-search-trigger').on('click', function(){

        if($(this).hasClass('js-search-open')) {
            $(this).removeClass('js-search-open');
            searchField.removeClass('js-search-open');
            searchField.find('input').blur();
            searchField.find('select').hide();
        } else {
            $(this).addClass('js-search-open');
            searchField.addClass('js-search-open');
            searchField.find('input').delay(1000).queue(function(){
                $(this).focus();
            });
        }
    });
});

/*******************************************/
// Nav dropdown
$('.js-dropdown-trigger').on('click', function(){
    $(this).toggleClass('active-dropdown');
    $('body').toggleClass('active-dropdown');
});

/*******************************************/
// Account dropdown
$(document).ready(function() {

    $('.js-account-trigger, .js-account-close').on('click', function(e){
        e.stopPropagation();
        var dropdownEl = $(this).parent();

        if(dropdownEl.hasClass('account-open')) {
            dropdownEl.removeClass('account-open');
        } else {
            dropdownEl.addClass('account-open');
        }
    });
});

/*******************************************/
// Popup Module
$(document).on('click', '.js-popup-module-trigger', function(e){
    var popupTarget = $(this).attr('href');
    e.preventDefault();
    $(document).find(popupTarget).addClass('open');
});
$(document).on('click', '.js-popup-module-bg, .js-popup-module-close', function(){
    $(this).closest('.js-popup-module').removeClass('open');
});

/*******************************************/
// integer quantity input

jQuery('<div class="quantity-nav"><div class="quantity-button quantity-up"><span class="icon-angle-up"></span></div><div class="quantity-button quantity-down"><span class="icon-angle-down"></span></div></div>').insertAfter('.js-quantity-input input');
jQuery('.js-quantity-input').each(function() {
    var spinner = jQuery(this),
        input = spinner.find('input[type="number"]'),
        btnUp = spinner.find('.quantity-up'),
        btnDown = spinner.find('.quantity-down'),
        min = input.attr('min'),
        max = input.attr('max');

    btnUp.click(function() {
        var oldValue = parseFloat(input.val());
        if (oldValue >= max) {
        var newVal = oldValue;
        } else {
        var newVal = oldValue + 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
    });

    btnDown.click(function() {
        var oldValue = parseFloat(input.val());
        if (oldValue <= min) {
        var newVal = oldValue;
        } else {
        var newVal = oldValue - 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
    });

});

/**************************************/
//Print page function

$('.js-print-page').click(function(){
    window.print();
});