var wishlist = new Wishlist();
/*
 * Requires data-id attribute of product ID
 * You may give the quantity field the id qty-{ID}
 */
var $addToWishlistBtn = $('.do-add-to-wishlist');
/*
 * Requires data-key attribute (session key)
 */
var $deleteFromWishlistBtn = $('.do-delete-from-wishlist');
/*
 * This is the select dropdowns with variant options
 */
var $variantSelects = $('select.variants');
/*
 * Requires data-id attribute of product ID
 * Add this class to quantity input items within the wishlist
 */
var $quantityField = $('.do-change-quantity-from-wishlist');

/*
 * Apply this class to any items which holds the wishlist count
 */
var $wishlistCounters = $('.wishlist-counter');

/*
 * Add this class to checkout continue buttons so they are hidden during AJAX requests
 */
var $wishlistHideOnAjax = $('.js-hide-on-ajax');

$(document).ready(function(){
    $addToWishlistBtn.click(function(e){
    	e.preventDefault();
        addToWishlist($(this));
        return false;
    });

    $quantityField.change(function(){
        updateQuantity($(this));
    });

    $deleteFromWishlistBtn.click(function(){
        deleteWishlistItem($(this));
    });
});


/****************************************************************************************************************************
 WISHLIST FUNCTIONS
 ****************************************************************************************************************************/

/**
 *
 */
function addToWishlist($selector)
{
    var qty = parseInt($('#qty-' + $selector.data('id')).val());
    if (!qty || qty == 0) {
        qty = 1;
    }

    if (qty > 0) {

    	var data = {
    		'id': $selector.data('id'),
	        'variants': [],
	        'qty': qty
	    };

	    $wishlistHideOnAjax.hide();
	    wishlist.updateQty(data, function() {
	    	wishlistUpdateHtml(wishlist);
	        showAlert('success', 'Item has been added to your wishlist');
	    }, true);
    }
}

function updateQuantity($selector)
{
    var itemId = $selector.data('id');
    var qty = parseInt($selector.val());
    if (qty == 0) qty = 1;

    var data = {
        '_token': $('input[name="_token"]').val(),
        'id': itemId,
        'variants': getVariants($selector.data('variants')),
        'qty': qty
    };

    $wishlistHideOnAjax.hide();
    wishlist.updateQty(data, function(){
        wishlistUpdateHtml(wishlist);
    });
}

function deleteWishlistItem($selector)
{
    if ($selector.data('key') != '') {
        var key = $selector.data('key');
        $wishlistHideOnAjax.hide();
        wishlist.removeItem({key: key}, function() {
            $('#' + key).remove();
            wishlistUpdateHtml(wishlist);
        });
    }
}

/****************************************************************************************************************************
 PRIVATE FUNCTIONS
 ****************************************************************************************************************************/
function wishlistUpdateHtml(wishlist)
{
    wishlistUpdateItems(wishlist);
    $wishlistCounters.html(wishlist.count);
}

function wishlistUpdateItems(wishlist)
{
    if (wishlist.items) {
        $.each(wishlist.items, function(key, value) {
            var $row = $('#' + key);
            if ($row.length > 0) {
                // var $price = $row.find('.js-price');
                // var $unitPrice = $row.find('.js-unit-price');

                // var itemPrice = parseFloat(value.price);
                // var priceHtml = (itemPrice * value.qty).toFixed(2);

                // if ($price.html().indexOf('$') !== -1)
                //     priceHtml = '$' + priceHtml;

                // $price.html(priceHtml);
                // $unitPrice.html(itemPrice.toFixed(2));
            }
        });
    }
}

function getVariantIds($selector)
{
    var variantIds = [];
    var $parent = $selector.closest('.ecommerce-actions');
    var $children = $parent.find('.variants');

    if ($children.length) {
    	$children.each(function(index) {
    		if ($(this).val() != null) {
    			variantIds[index] = $(this).val();
        	}
        });

    	if (variantIds.length > 0) {
    		return variantIds;
    	}
    }

    return null;
}

function getVariants(string)
{
    var v = String(string);
    var variants = null;
    if (v.indexOf(',') > -1){
        variants = v.split(',')
    } else if (v != 'undefined' && v != null && v != '') {
        variants = [v];
    }

    return variants;
}