// cart API
// handles ajax calls

function Cart(){
    var _this = this;

    this.items = {};
    this.count = 0;
    this.subtotal = 0;
    this.shipping = 0;
    this.discount = 0;
    this.total = 0;
    this.gst = 0;
    this.coupon = 0;
    this.coupon_code = '';
    this.status = 0;
    this.errors = '';

    this.price = function(price) {
        return Number(price).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };

    this.updateQty = function(data, callback, increment) {
        $.ajax({
            type: increment ? 'POST' : 'PUT',
            url: '/rest/cart',
            data: data,
            success: function(data) {
                if (data.status && data.status == 'combo-disabled') {
                    alert('Sorry, this combination of variants is not available.');
                    _this.status = 0;
                } else if (data.status == 'success') {
                    _this.setCartData(data);
                } else {
                    alert(data.message);
                }
                if (typeof(callback) != 'undefined') callback();
            },
        	error: function(response) {
        		showAlert('error', response.responseText);
        	}
        });
    };

    //the ONE!!
    this.updateShipping = function(regionId, callback) {
        $.ajax({
            type: 'GET',
            url: '/rest/shipping/' + regionId,
            success: function (data) {
                if (data.status == 'success') {
                    _this.setCartData(data);
                } else {
                	showAlert('error', data.message);
                }
                if (typeof(callback) != 'undefined') callback();
            },
        	error: function(response) {
        		showAlert('error', response.responseText);
        	}
        });
    };

    this.removeItem = function(data, callback) {
        $.ajax({
            type: 'GET',
            url: '/rest/cart/remove/' + data.key,
            success: function(data) {
                if (data.status == 'success') {
                    _this.setCartData(data);
                } else {
                	showAlert('error', 'Unable to remove item on the cart');                    
                }
                if (typeof(callback) != 'undefined') callback();
            },
        	error: function(response) {
        		showAlert('error', response.responseText);
        	}
        });
    };

    this.saveBilling = function(data, callback) {
        _this.errors = '';
        $.ajax({
            type: 'POST',
            url: '/rest/cart/saveBilling',
            data: data,
            success: function(data) {
                if (data.status == 'success') {
                    _this.setCartData(data);
                } else {
                	showAlert('error', 'Unable to save your billing details.');
                }    	
                if (typeof(callback) != 'undefined') callback();
            },
            error: function (response) {
                if (response.status === 422 && response.responseJSON)
                    _this.errors = _this.getErrorsFromJson(response.responseJSON);
                else
                    alert(response);

                if (typeof(callback) != 'undefined') callback();
            },
        	error: function(response) {
        		showAlert('error', response.responseText);
        	}
        });
    };

    this.saveAddress = function(data, callback) {
        _this.errors = '';
        $.ajax({
            type: 'POST',
            url: '/rest/cart/saveAddress',
            data: data,
            success: function(data) {
                if (data.status == 'success') {
                    _this.setCartData(data);
                } else {
                	showAlert('Unable to save your address details.');
                }
                
                if (typeof(callback) != 'undefined') callback();
            },
            error: function (response) {
            	 if (response.status === 422 && response.responseJSON) {
                     _this.errors = _this.getErrorsFromJson(response.responseJSON);
            	 } else {                     
                     showAlert('error', response.responseText);
            	 }    
            	 
                if (typeof(callback) != 'undefined') callback();
            }
        });
    };

    this.addCoupon = function(coupon, callback) {
        $.ajax({
            type: 'GET',
            url: '/rest/cart/coupon/' + coupon,
            success: function(data) {
                if (data.status == 'success') {
                    _this.setCartData(data);
                } else {
                    _this.status    	= 0;
                    alert(data.message);
                }
                if (typeof(callback) != 'undefined') callback();
            },
        	error: function(response) {
        		showAlert('error', response.responseText);
        	}
        });
    };

    this.removeCoupon = function(callback) {
        $.ajax({
            type: 'GET',
            url: '/rest/cart/removeCoupon',
            success: function(data) {
                if (data.status == 'success') {
                    _this.setCartData(data);
                } else {
                    _this.status    	= 0;
                    alert(data.message);
                }
                if (typeof(callback) != 'undefined') callback();
            },
        	error: function(response) {
        		showAlert('error', response.responseText);
        	}
        });
    };

    this.sendPayment = function(data, callback) {
        _this.errors = '';
        $.post('/payment/send', data, function(response) {
            if (response.status == 'fail')
                _this.errors = response.message;
            else if (response.status != 'success')
                _this.errors = 'An internal error has occurred, please try making payment again.';

            if (typeof(callback) != 'undefined') callback();
        }).fail(function(response){
            if (response.status === 422 && response.responseJSON)
                _this.errors = _this.getErrorsFromJson(response.responseJSON);
            else
                _this.errors = 'An internal error occurred, please try again.';

            if (typeof(callback) != 'undefined') callback();
        });
    };

    this.setCartData = function(data) {
        _this.items     = data.cart.items;
        _this.count     = data.cart.count;
        _this.subtotal  = data.cart.subtotal;
        _this.shipping  = data.cart.shipping;
        _this.gst       = data.cart.gst;
        _this.discount  = data.cart.discount;
        _this.total     = data.cart.total;
        _this.discount  = data.cart.discount;
        _this.coupon    = data.cart.coupon;
        _this.coupon_code = data.cart.coupon_code;
        _this.status    = 1;
    };

    this.getErrorsFromJson = function(json) {
        var errors = '';        
        $.each(json, function(key, value){
        	var string = value[0];
        	var replacements = ['"', '{', '}', '[', ']'];
        	var index = 0;
        	for (index = 0; index < replacements.length; index++) {
        		string = string.replace(replacements[index], '');
        	}
            errors += string + "\n";
        });
        	
        return errors;
    };
}
