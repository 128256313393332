var cart = new Cart();
/*
 * Requires data-id attribute of product ID
 * You may give the quantity field the id qty-{ID}
 */
var $addToCartBtn = $('.do-add-to-cart');
/*
 * Requires data-key attribute (session key)
 */
var $deleteFromCartBtn = $('.do-delete-from-cart');
/*
 * This is the select dropdowns with variant options
 */
var $variantSelects = $('select.variants');

/*
 * Requires data-id attribute of product ID
 * Add this class to quantity input items within the cart
 */
var $quantityField = $('.do-change-quantity');
/*
 * Coupon code input field
 */
var $couponField = $('.coupon-code');
/*
 * Apply coupon button
 */
var $applyCouponBtn = $('.do-apply-coupon');
/*
 * Coupon row which will be unhidden when coupon applied
 */
var $couponRow = $('.coupon-row');
/*
 * Delete coupon button
 */
var $deleteCouponBtn = $('.do-delete-coupon');
/*
 * Region select
 */
var $regionField = $('.do-update-region');
/*
 * Copy billing to shipping button
 */
var $copyBillingToShippingBtn = $('.do-copy-billing');
/*
 * Billing and shipping forms
 */
var $billingForm = $('.billing-form');
var $addressForm = $('.address-form');
/*
 * Payment Form (DPS Hosted)
 */
var $paymentForm = $('.payment-form');
/*
 * Apply this class to any items which holds the cart count
 */
var $cartCounters = $('.cart-counter');
/*
 * The cart totals tags in the cart and checkout pages
 */
var totals = {
    subtotal: $('.js-subtotal'),
    coupon_name: $('.js-coupon-name'),
    coupon_amount: $('.js-coupon-amount'),
    shipping: $('.js-shipping'),
    gst: $('.js-gst'),
    total: $('.js-total')
};
/*
 * Add this class to checkout continue buttons so they are hidden during AJAX requests
 */
var $cartHideOnAjax = $('.js-hide-on-ajax');
/*
 * Save shipping options
 */
var $saveShippingOptions = $('.do-save-shipping-options');
/*
 * Save gift card messages
 */
var $saveGiftCardMessages = $('.do-save-gift-card-messages');

$(document).ready(function(){
    $addToCartBtn.click(function(e){
    	e.preventDefault();
        addToCart($(this));
        return false;
    });

    $quantityField.change(function(){
        console.log('changed value');
        updateQuantityCart($(this));
    });

    $deleteFromCartBtn.click(function(){
        deleteCartItem($(this));
    });

    $applyCouponBtn.click(function(){
        applyCoupon($(this));
    });

    $deleteCouponBtn.click(function(){
        removeCoupon();
    });

    $regionField.change(function(){
        changeRegion($(this));
    });

    $regionField.trigger('change');

    $copyBillingToShippingBtn.click(function(){
        copyBillingToShipping($(this));
    });

    $addressForm.submit(function(e){
    	e.preventDefault();
        saveAddress($(this));
    });

    $('.js-edit-billing-btn').click(function(event) {
    	event.preventDefault();
    	$('.js-editable-form').show();
		$('.js-btn-shipping').show();
		$(this).hide();
		$('.js-non-editable-form').hide();
    });

});


/****************************************************************************************************************************
 CART FUNCTIONS
 ****************************************************************************************************************************/

/**
 *
 */
function addToCart($selector)
{
    var qty = parseInt($('#qty-' + $selector.data('id')).val());
    if (!qty || qty == 0) {
        qty = 1;
    }

    if (qty > 0) {

    	var data = {
    		'id': $selector.data('id'),
	        'variants': [],
	        'qty': qty
	    };

	    $cartHideOnAjax.hide();
	    cart.updateQty(data, function() {
	    	cartUpdateHtml(cart);
	        showAlert('success', 'Item has been added to cart');
	    }, true);
    }
}

function updateQuantityCart($selector)
{
    var itemId = $selector.data('id');
    var qty = parseInt($selector.val());
    if (qty == 0) qty = 1;

    var data = {
        '_token': $('input[name="_token"]').val(),
        'id': itemId,
        'variants': getVariants($selector.data('variants')),
        'qty': qty
    };

    $cartHideOnAjax.hide();
    cart.updateQty(data, function(){
        cartUpdateHtml(cart);
    });
}

function deleteCartItem($selector)
{
    if ($selector.data('key') != '') {
        var key = $selector.data('key');
        $cartHideOnAjax.hide();
        cart.removeItem({key: key}, function() {
            $('#' + key).remove();
            cartUpdateHtml(cart);
        });
    }
}

/**
 *
 */
function saveAddress($form)
{
    cart.saveAddress($form.serializeArray(), function(){
        if (cart.errors != '') {
            showAlert('error', cart.errors);
        } else {
            // Reload the page so the new address can be shown
        	this.triggerStep($form.find("button[type='submit']"));

        	var formData = $form.serializeArray();

        	$.each(formData, function(index, object) {

        		if (object.name == 'region_id') {
        			$('.js-' + object.name).text($("select.region_id option[value='"+object.value+"']").text());
        		} else if (object.name == 'country_id') {
        			$('.js-' + object.name).text($("select.country_id option[value='"+object.value+"']").text());
        		} else {
        			$('.js-' + object.name).text(object.value);
        		}
        	});

            cartUpdateHtml(cart);
        }
    });
}

/****************************************************************************************************************************
 COUPON FUNCTIONS
 ****************************************************************************************************************************/
function applyCoupon($selector)
{
    var coupon = $couponField.val();
    if (coupon != '') {
        $cartHideOnAjax.hide();
        cart.addCoupon(coupon, function(){
            cartUpdateHtml(cart);
        });
    }
}

function removeCoupon()
{
    $cartHideOnAjax.hide();
    cart.removeCoupon(function(){
        cartUpdateHtml(cart);
    });
}

/****************************************************************************************************************************
 SHIPPING FUNCTIONS
 ****************************************************************************************************************************/
function changeRegion($selector)
{
    if ($selector.val() > 0) {
        $cartHideOnAjax.hide();
        cart.updateShipping($selector.val(), function() {
            cartUpdateHtml(cart);
        });
    } else {
        //todo: Throw some sort of error?
    }
}

/**
 *
 */
function copyBillingToShipping($selector)
{
	if($selector.is(":checked")) {
        $billingForm.find('input, select').each(function() {
            var name = $(this).attr('name');
            var $field = $addressForm.find("[name='"+name+"']");

            if($field.length) {
                $(this).val($field.val())
            }
        });
    } else {
        $billingForm.find('input, select').each(function() {
            this.value = '';
        })
    }
}

/****************************************************************************************************************************
 PRIVATE FUNCTIONS
 ****************************************************************************************************************************/
function cartUpdateHtml(cart)
{
    cartUpdateItems(cart);
    $cartCounters.html(cart.count);
    totals.subtotal.html(cart.subtotal.toFixed(2));
    if(cart.shipping > 0)
        totals.shipping.html(cart.shipping.toFixed(2));
    totals.gst.html(cart.gst.toFixed(2));
    totals.total.html(cart.total.toFixed(2));
    totals.coupon_name.html(cart.coupon_code);
    totals.coupon_amount.html(parseFloat(cart.coupon).toFixed(2));

    if (cart.coupon_code) {
        $couponRow.show();
    } else {
        $couponRow.hide();
        $cartHideOnAjax.show();
    }

}

function cartUpdateItems(cart)
{
    if (cart.items) {
        $.each(cart.items, function(key, value) {
            var $row = $('#' + key);
            if ($row.length > 0) {
                var $price = $row.find('.js-price');
                var $unitPrice = $row.find('.js-unit-price');

                var itemPrice = parseFloat(value.price);
                var priceHtml = (itemPrice * value.qty).toFixed(2);

                if ($price.html().indexOf('$') !== -1)
                    priceHtml = '$' + priceHtml;

                $price.html(priceHtml);
                $unitPrice.html(itemPrice.toFixed(2));
            }
        });
    }
}

function getVariantIds($selector)
{
    var variantIds = [];
    var $parent = $selector.closest('.ecommerce-actions');
    var $children = $parent.find('.variants');

    if ($children.length) {
    	$children.each(function(index) {
    		if ($(this).val() != null) {
    			variantIds[index] = $(this).val();
        	}
        });

    	if (variantIds.length > 0) {
    		return variantIds;
    	}
    }

    return null;
}

function getVariants(string)
{
    var v = String(string);
    var variants = null;
    if (v.indexOf(',') > -1){
        variants = v.split(',')
    } else if (v != 'undefined' && v != null && v != '') {
        variants = [v];
    }

    return variants;
}

function triggerStep($selector)
{
	var $form = $selector.closest('form');
	if ($form.hasClass('enable-step')) {
		//$('.checkout-step-item').removeClass('open-step');
		$('.js-editable-form').hide();
		$('.js-btn-shipping').hide();
		$('.js-edit-billing-btn').show();
		$('.js-non-editable-form').show();
		$form.next().addClass('open-step enable-step');
	}
}