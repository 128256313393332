// wishlist API
// handles ajax calls

function Wishlist(){
    var _this = this;

    this.items = {};
    this.count = 0;
    this.status = 0;
    this.errors = '';

    this.updateQty = function(data, callback, increment) {
        $.ajax({
            type: increment ? 'POST' : 'PUT',
            url: '/rest/wishlist',
            data: data,
            success: function(data) {
                if (data.status && data.status == 'combo-disabled') {
                    alert('Sorry, this combination of variants is not available.');
                    _this.status = 0;
                } else if (data.status == 'success') {
                    _this.setWishlistData(data);
                } else {
                    alert(data.message);
                }
                if (typeof(callback) != 'undefined') callback();
            },
        	error: function(response) {
        		showAlert('error', response.responseText);
        	}
        });
    };

    this.removeItem = function(data, callback) {
        $.ajax({
            type: 'GET',
            url: '/rest/wishlist/remove/' + data.key,
            success: function(data) {
                if (data.status == 'success') {
                    _this.setWishlistData(data);
                } else {
                	showAlert('error', 'Unable to remove item on the wishlist');
                }
                if (typeof(callback) != 'undefined') callback();
            },
        	error: function(response) {
        		showAlert('error', response.responseText);
        	}
        });
    };

    this.setWishlistData = function(data) {
        _this.items     = data.wishlist.items;
        _this.count     = data.wishlist.count;
        _this.status    = 1;
    };

    this.getErrorsFromJson = function(json) {
        var errors = '';        
        $.each(json, function(key, value){
        	var string = value[0];
        	var replacements = ['"', '{', '}', '[', ']'];
        	var index = 0;
        	for (index = 0; index < replacements.length; index++) {
        		string = string.replace(replacements[index], '');
        	}
            errors += string + "\n";
        });
        	
        return errors;
    };
}
